import React, { useEffect } from 'react';

const ApexChat = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.defer = true;
    script.async = true;
    script.src = 'https://widget.apexvisibility.co/dist/embed-script.js';
    script.setAttribute('data-webchat-site-id', 'baa877b3-cd1c-4bd4-b3f7-e4c428573b8b');
    
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(script, firstScript);

    // Clean up the effect by removing the script when the component unmounts
    return () => {
      script.remove();
    };
  }, []);

  return (
    <div>
      {/* You can add any additional markup or components here */}
    </div>
  );
};

export default ApexChat;
