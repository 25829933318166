import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function SecurityNotification({ isVpnTrue, isResrtictedLink }) {

    const [vpnModal, setVpnModal] = useState(false);
    const [restrictedLinks, setRestrictedLinks] = useState(false);

    useEffect(() => {
        if (isResrtictedLink) {
            setRestrictedLinks(true)
        } else if (isVpnTrue) {
            setVpnModal(true)
        }
        console.log(isVpnTrue)
    }, [isVpnTrue, isResrtictedLink]);

    return (
        <>
            {/* vpn modal  */}
            <Modal
                show={vpnModal}
                backdrop="static"
                keyboard={false}
                className='security-modal-wrapper'
            >
                <Modal.Header>
                    <Modal.Title>
                        <h2 className='security-modal-title'>VPN Usage Detected</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='security-modal-description'>
                        It appears you are using a VPN. For security reasons, please disable your VPN and try accessing the service again. If you need further assistance, please contact customer support team.
                    </p>

                    <h4 className='security-modal-contact-info-title'>Contact Information:</h4>
                    <p>Email: <strong>reservation@ovholidays.com</strong></p>
                    <p>Phone/ Whatsapp: <strong>+960 798 5454</strong></p>
                </Modal.Body>
            </Modal>

            {/* restricted modal  */}
            <Modal
                show={restrictedLinks}
                backdrop="static"
                keyboard={false}
                className='security-modal-wrapper'
            >
                <Modal.Header>
                    <Modal.Title>
                        <h2 className='security-modal-title'>Unverified Redirect Link</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='security-modal-description'>
                        The link you used to access this page is not verified. Please contact customer support team for more details and assistance
                    </p>

                    <h4 className='security-modal-contact-info-title'>Contact Information:</h4>
                    <p>Email: <strong>reservation@ovholidays.com</strong></p>
                    <p>Phone/ Whastapp: <strong>+960 798 5454</strong></p>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default SecurityNotification;
