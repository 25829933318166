import React, { useState, useEffect } from "react";
import MediaQuery from "react-responsive";
import { NavLink } from "react-router-dom";
import FormLabel from "@material-ui/core/FormLabel";
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import { InputNumber, Input } from 'rsuite';
import { Spinner } from 'react-bootstrap';
import DateFnsUtils from '@date-io/date-fns';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import detectBrowserLanguage from 'detect-browser-language'

function OfferConsultingForm({ closeExitForm }) {

    const [languageStaticContent, setLanguageStaticContent] = useState(require(`../../assets/json/lang/en.json`));
    const [arabicTrue, setArabicTrue] = useState(false);

    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [selectedTime, setSelectedTime] = React.useState(new Date());
    const [buttonText, setButtonText] = useState(languageStaticContent.free_consultation);

    const todaydateTime = new Date();

    const [selectedDateText, setSelectedDateText] = React.useState(`${todaydateTime.getFullYear()}-${(todaydateTime.getMonth() + 1).toString().padStart(2, '0')}-${todaydateTime.getDate().toString().padStart(2, '0')}`);

    const nowHours = todaydateTime.getHours();
    const nowMinutes = todaydateTime.getMinutes();
    const [selectedTimeText, setSelectedTimeText] = React.useState(`${nowHours % 12}:${nowMinutes.toString().padStart(2, '0')} ${nowHours >= 12 ? 'PM' : 'AM'} (${todaydateTime.toLocaleTimeString('en-US', { timeZoneName: 'short' })})`);

    const [value, setValue] = useState()
    const [countryId, setCountryId] = React.useState("");
    const [telNumber, setTelNumber] = React.useState("");

    const handleDateChange = (date) => {
        let originalDate = new Date();

        if (date != null) {
            originalDate = new Date(date);
        }

        const formattedDate = `${originalDate.getFullYear()}-${(originalDate.getMonth() + 1).toString().padStart(2, '0')}-${originalDate.getDate().toString().padStart(2, '0')}`;
        setSelectedDate(originalDate)
        setSelectedDateText(formattedDate);
    };

    const handleTimeChange = (time) => {
        let originalDate = new Date();

        if (time != null) {
            originalDate = new Date(time);
        }

        setSelectedTime(originalDate)
        const hours = originalDate.getHours();
        const minutes = originalDate.getMinutes();
        const formattedTime = `${hours % 12}:${minutes.toString().padStart(2, '0')} ${hours >= 12 ? 'PM' : 'AM'} (${originalDate.toLocaleTimeString('en-US', { timeZoneName: 'short' })})`;
        setSelectedTimeText(formattedTime);
    }
    const browserLanguage = detectBrowserLanguage();
    useEffect(() => {
        localStorage.setItem('formIsopened', true);
        if (browserLanguage === "ar") {
            setArabicTrue(true)
        }
        fetch("https://www.discoverymaldives.com/experience/get_location.php", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setCountryId(data.country_id);
                setTelNumber(data.telephone_no);
            })
            .catch((error) => {
                console.log("not work");
                console.log(error);
            });

        const fetchJsonFile = () => {
            try {
                const browserLanguage = detectBrowserLanguage();
                const jsonData = require(`../../assets/json/lang/${browserLanguage}.json`);
                setLanguageStaticContent(jsonData)
                setButtonText(languageStaticContent.free_consultation)
            } catch (error) {
                console.error(error);
            }
        };

        fetchJsonFile();

        return () => {
            localStorage.setItem('formIsopened', false);
        };
    }, [])

    const [errorLog, setErrorLog] = useState([]);
    const [isActive, setActive] = useState(false);
    const [telephone, setTelephone] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [userName, setUserName] = useState('');
    const [otpData, setOtpData] = useState([]);
    const [referanceID, setReferanceID] = useState('');
    const [otpNumber, setOTP] = useState('');
    const [isSendOtp, setisSendOtp] = useState(false)


    const handleUserNameChange = (event) => {
        const value = event.target ? event.target.value : event;
        setUserName(value);
    };

    const handleOTPnumber = (event) => {
        const value = event.target ? event.target.value : event;
        setOTP(value);
    };

    const formSubmitHandler = (event) => {
        event.preventDefault();
        let return_status = true;
        let errorList = [];
        setActive(true)
        setSuccessMessage("")
        if (telephone == "") {
            errorList.push(`Phone Number required`);
            return_status = false;
            setActive(false)
        }
        if (userName.trim() === '') {
            errorList.push('User Name required');
            return_status = false;
            setActive(false);
        }
        console.log(selectedDateText.trim())
        if (selectedDateText.trim() === null) {
            errorList.push('Date required');
            return_status = false;
            setActive(false);
        }

        if (selectedTimeText.trim() === null) {
            errorList.push('Time required');
            return_status = false;
            setActive(false);
        }


        setErrorLog(errorList);
        if (return_status) {
            //first check phone number
            if (!otpNumber && !isSendOtp) {
                fetch("https://www.viluxurholidays.com/srm/modules/api/V5/sendOtp.php?phoneNo=" + telephone, {
                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        setOtpData(data)
                        setReferanceID(data.referenceId)
                        setisSendOtp(true)
                        setActive(false);
                        setButtonText(languageStaticContent.confirm)
                    })
                    .catch((error) => {
                        console.log("not work");
                        console.log(error);
                    });
            } else {
                fetch("https://www.viluxurholidays.com/srm/modules/api/V5/verifyOTP.php?otpNumber=" + otpNumber + "&phoneNo=" + telephone, {
                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        console.log(data);
                        if (data.valid) { //
                            setActive(false);
                            setButtonText(languageStaticContent.free_consultation)
                            fetch('https://www.discoverymaldives.com/experience/thank-you.php', {
                                method: 'POST',
                                headers: {
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({
                                    fldcustomerFax: 0,
                                    fldCustomerFormType: 4,
                                    fldCustomerFormMessage1: "CallBack Form",
                                    fldAdVisitorID: localStorage.getItem("fldAdVisitorID"),
                                    adfrom: localStorage.getItem("adfrom"),
                                    keyword: localStorage.getItem("keyword"),
                                    first_landing_page: localStorage.getItem("first_landing_page"),
                                    code: localStorage.getItem("code"),
                                    agp: localStorage.getItem("agp"),
                                    camid: localStorage.getItem("camid"),
                                    fldCustomerEMail: localStorage.getItem('email'),
                                    fldCustomerMobilePhone: telephone,
                                    fldCustomerFName: userName,
                                    levelOfEnquiry: 1,
                                    history: sessionStorage.getItem('pageData'),
                                    fldCustomerFormMessage: "Selected Date : " + selectedDateText + " Selected Time : " + selectedTimeText,
                                    clientBrowserLanguage: browserLanguage
                                })
                            }).then((response) => response.json())
                                .then(data => {
                                    setActive(false)
                                    if (data.code === 200) {
                                        sessionStorage.removeItem('pageData')
                                        localStorage.setItem('isSendConsulting', true);
                                        window.location.href = "https://www.ovholidays.com/thankyou.php";
                                    } else {
                                        window.location.href = "https://www.ovholidays.com/errorovx.php";
                                    }
                                }).catch((error) => {
                                    setActive(false)
                                    setSuccessMessage("Something went wrong. Please Try Again Later")
                                });
                        } else {
                            setSuccessMessage("Verification failed.")
                        }

                    })
                    .catch((error) => {
                        console.log("not work");
                        console.log(error);
                    });
            }
        }
    }

    const ul_style = {
        padding: "0px",
        listStyle: "none",
        margin: "0px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center"
    };

    const offerTitle = {
        textAlign: "center"
    }
    const widthFixed = {
        height: "50px",
    };
    const margin_removed = {
        marginBottom: "0px",
    };


    return (
        <>
            <MediaQuery minDeviceWidth={768}>
                <div className="exit-intent-popup visible">
                    <div className="newsletter ov-custom-schedule-form d-flex justify-content-center">
                        <div className=" ov-bg-white">
                            <div className="">
                                <div className="">
                                    <button class="enquiry-close-icon" onClick={closeExitForm}>×</button>
                                    <div className="pl-50 pr-50 ov-input mb-4">
                                        <div>
                                            <p className={`ov-description teritory mt-5 pt-4 ${arabicTrue ? 'arabic-text-right' : ''}`}>
                                                {languageStaticContent.Sch_You_Fre_Con_Tod}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="pl-50 pr-50">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <div className="form-wrapper">
                                                <div className="">
                                                    <div className="mb-3">
                                                        <FormLabel className={`margin-top-form mb-0 ${arabicTrue ? 'arabic-text-right' : ''}`} component="legend">{languageStaticContent.Your_Name} *</FormLabel>
                                                        <div className="consulting-input-filed-prepend">
                                                            <Input
                                                                className={`mobilenumberInput w-100 input-font-new ${arabicTrue ? 'arabic-text-right' : ''}`}
                                                                value={userName}
                                                                type="text"
                                                                onChange={handleUserNameChange}

                                                            />
                                                        </div>
                                                        {errorLog.includes('User Name required') && (
                                                            <p className={`ov-description xtra-small font-red ${arabicTrue ? 'arabic-text-right' : ''}`}>{languageStaticContent.Ple_ent_you_nam}</p>
                                                        )}
                                                    </div>

                                                    <div className="mb-3">
                                                        <FormLabel className={`margin-top-form mb-0 ${arabicTrue ? 'arabic-text-right' : ''}`} component="legend">{languageStaticContent.Pick_a_date}</FormLabel>
                                                        <KeyboardDatePicker
                                                            margin="normal"
                                                            id="date-picker-dialog"
                                                            label=""
                                                            format="MM/dd/yyyy"
                                                            value={selectedDate}
                                                            onChange={handleDateChange}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                            style={{ textAlign: arabicTrue ? "right" : "left" }} // Aligns the text to the right
                                                            inputProps={{ style: { textAlign: arabicTrue ? "right" : "left" } }}
                                                        />
                                                        {errorLog.includes('Date required') && (
                                                            <p className={`ov-description xtra-small font-red ${arabicTrue ? 'arabic-text-right' : ''}`}>{languageStaticContent.Date_required}</p>
                                                        )}
                                                    </div>
                                                    <div className="mb-3">
                                                        <FormLabel className={`margin-top-form mb-0 ${arabicTrue ? 'arabic-text-right' : ''}`} component="legend">{languageStaticContent.Pick_A_time}</FormLabel>
                                                        <KeyboardTimePicker
                                                            margin="normal"
                                                            id="time-picker"
                                                            label=""
                                                            format="HH:mm"
                                                            value={selectedTime}
                                                            onChange={handleTimeChange}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change time',
                                                            }}
                                                            style={{ textAlign: arabicTrue ? "right" : "left" }} // Aligns the text to the right
                                                            inputProps={{ style: { textAlign: arabicTrue ? "right" : "left" } }}
                                                        />
                                                        {errorLog.includes('Time required') && (
                                                            <p className="ov-description xtra-small  font-red">{languageStaticContent.Time_required}</p>
                                                        )}
                                                    </div>
                                                    <div className="mb-3">
                                                        <FormLabel className={`margin-top-form mb-3  ${arabicTrue ? 'arabic-text-right' : ''}`} component="legend">{languageStaticContent.Confirm_your_Number_}</FormLabel>
                                                        <div className="consulting-input-filed-prepend">
                                                            <PhoneInput className="mobilenumberInput w-100 input-font-new"
                                                                international
                                                                countryCallingCodeEditable={false}
                                                                defaultCountry={localStorage.getItem('country')}
                                                                value={telephone}
                                                                onChange={setTelephone} />
                                                        </div>
                                                        {errorLog.includes('Phone Number required') && (
                                                            <p className={`ov-description xtra-small font-red ${arabicTrue ? 'arabic-text-right' : ''}`}>{languageStaticContent.Pho_Num_req}</p>
                                                        )}

                                                    </div>

                                                    {isSendOtp &&
                                                        <div className="mb-3">
                                                            <FormLabel className={`margin-top-form mb-0 ${arabicTrue ? 'arabic-text-right' : ''}`} component="legend">{languageStaticContent.OTP}</FormLabel>
                                                            <div className="consulting-input-filed-prepend">
                                                                <Input
                                                                    className={`mobilenumberInput w-100 input-font-new ${arabicTrue ? 'arabic-text-right' : ''}`}
                                                                    value={otpNumber}
                                                                    type="text"
                                                                    onChange={handleOTPnumber}
                                                                />
                                                            </div>
                                                            {errorLog.includes('OTP required') && (
                                                                <p className={`ov-description xtra-small  font-red ${arabicTrue ? 'arabic-text-right' : ''}`}>{languageStaticContent.Please_enter_OTP}</p>
                                                            )}
                                                        </div>
                                                    }


                                                    <div className="pl-50 pr-50">
                                                        <p className="ov-font-14 body-new">
                                                            {successMessage}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="text-center mt-4">
                                                    <button className="ov_button primary-button mb-4" variant="contained" color="primary" type="button" onClick={formSubmitHandler}>{buttonText}
                                                        <Spinner
                                                            as="span"
                                                            variant="light"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            animation="border"
                                                            className={isActive ? "spinnerSubmitactive" : "spinnerSubmitinactive"}
                                                        /></button>
                                                </div>
                                            </div>
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <button className="close" >
                            
                        </button> */}

                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxDeviceWidth={767}>
                <div className="exit-intent-popup visible">
                    <div className="newsletter ov-custom-schedule-form ov-mobile-popup">
                        <div className="ov-bg-white">
                            <div className="">
                                <div className="ov-custom-height">
                                    <div className="pl-50 pr-50 d-flex mb-5 ov-input">
                                        <div>
                                            <p className={`ov-description teritory enquiry-main-title mt-4 pt-3 pl-0 pr-0 ${arabicTrue ? 'arabic-text-right' : ''}`}>
                                            {languageStaticContent.Sch_You_Fre_Con_Tod}
                                            </p>
                                        </div>
                                        <div className="close">
                                            <button className="enquiry-close-icon" onClick={closeExitForm}>×</button>
                                        </div>

                                    </div>
                                    <div className="pl-50 pr-50">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <div className="form-wrapper">
                                                <div className="">
                                                    <div className="mb-5">
                                                        <FormLabel className={`margin-top-form mb-0 ${arabicTrue ? 'arabic-text-right' : ''}`} component="legend">{languageStaticContent.Your_Name} *</FormLabel>
                                                        <div className="consulting-input-filed-prepend">
                                                            <Input
                                                                className={`mobilenumberInput w-100 input-font-new  ${arabicTrue ? 'arabic-text-right' : ''}`}
                                                                value={userName}
                                                                type="text"
                                                                onChange={handleUserNameChange}
                                                            />
                                                        </div>
                                                        {errorLog.includes('User Name required') && (
                                                            <p className={`ov-description xtra-small font-red ${arabicTrue ? 'arabic-text-right' : ''}`}>{languageStaticContent.Ple_ent_you_nam}</p>
                                                        )}
                                                    </div>
                                                    <div className="mb-5">
                                                        <FormLabel className={`margin-top-form mb-0 ${arabicTrue ? 'arabic-text-right' : ''}`} component="legend">{languageStaticContent.Pick_a_date}</FormLabel>
                                                        <KeyboardDatePicker
                                                            margin="normal"
                                                            id="date-picker-dialog"
                                                            label=""
                                                            format="MM/dd/yyyy"
                                                            value={selectedDate}
                                                            onChange={handleDateChange}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                            style={{ textAlign: arabicTrue ? "right" : "left" }} // Aligns the text to the right
                                                            inputProps={{ style: { textAlign: arabicTrue ? "right" : "left" } }}
                                                        />
                                                        {errorLog.includes('Date required') && (
                                                            <p className={`ov-description xtra-small font-red ${arabicTrue ? 'arabic-text-right' : ''}`}>{languageStaticContent.Date_required}</p>
                                                        )}
                                                    </div>
                                                    <div className="mb-5">
                                                        <FormLabel className={`margin-top-form mb-0 ${arabicTrue ? 'arabic-text-right' : ''}`} component="legend">{languageStaticContent.Pick_A_time}</FormLabel>
                                                        <KeyboardTimePicker
                                                            margin="normal"
                                                            id="time-picker"
                                                            label=""
                                                            format="HH:mm"
                                                            value={selectedTime}
                                                            onChange={handleTimeChange}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change time',
                                                            }}
                                                            style={{ textAlign: arabicTrue ? "right" : "left" }} // Aligns the text to the right
                                                            inputProps={{ style: { textAlign: arabicTrue ? "right" : "left" } }}
                                                        />
                                                        {errorLog.includes('Time required') && (
                                                            <p className="ov-description xtra-small  font-red">{languageStaticContent.Time_required}</p>
                                                        )}
                                                    </div>
                                                    <div className="mb-5">
                                                        <FormLabel className={`margin-top-form mb-2 ${arabicTrue ? 'arabic-text-right' : ''}`} component="legend">{languageStaticContent.Confirm_your_Number_} *</FormLabel>
                                                        <div className="consulting-input-filed-prepend">
                                                            <PhoneInput className="mobilenumberInput w-100 input-font-new"
                                                                international
                                                                countryCallingCodeEditable={false}
                                                                defaultCountry={localStorage.getItem('country')}
                                                                value={telephone}
                                                                onChange={setTelephone} />
                                                        </div>
                                                        {errorLog.includes('Phone Number required') && (
                                                            <p className={`ov-description xtra-small font-red ${arabicTrue ? 'arabic-text-right' : ''}`}>{languageStaticContent.Pho_Num_req}</p>
                                                        )}

                                                    </div>

                                                    {isSendOtp &&
                                                        <div className="mb-5">
                                                            <FormLabel className={`margin-top-form mb-2 ${arabicTrue ? 'arabic-text-right' : ''}`} component="legend">{languageStaticContent.OTP}</FormLabel>
                                                            <div className="consulting-input-filed-prepend">
                                                                <Input
                                                                    className={`mobilenumberInput w-100 input-font-new ${arabicTrue ? 'arabic-text-right' : ''}`}
                                                                    value={otpNumber}
                                                                    type="text"
                                                                    onChange={handleOTPnumber}
                                                                />
                                                            </div>
                                                            {errorLog.includes('OTP required') && (
                                                                <p className={`ov-description xtra-small  font-red ${arabicTrue ? 'arabic-text-right' : ''}`}>{languageStaticContent.Please_enter_OTP}</p>
                                                            )}
                                                        </div>
                                                    }

                                                    <div className="text-center">
                                                        <p className="mb-4">
                                                            {successMessage}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="text-center">
                                                    <button className="ov_button primary-button mb-4" variant="contained" color="primary" type="button" onClick={formSubmitHandler}>{buttonText}
                                                        <Spinner
                                                            as="span"
                                                            variant="light"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            animation="border"
                                                            className={isActive ? "spinnerSubmitactive" : "spinnerSubmitinactive"}
                                                        /></button>
                                                </div>

                                            </div>
                                        </MuiPickersUtilsProvider>
                                    </div>

                                </div>



                            </div>
                        </div>

                    </div>
                </div>
            </MediaQuery>
        </>
    )
}

export default OfferConsultingForm;

